<script>
import {
  camelCaseKeys, LOG_TYPE, logger, navigationErrorHandler,
} from '@emobg/web-utils';

import ALERT_TYPES from '@/components/Alert/alertTypes';

import AlertComponent from '@/components/Alert/AlertComponent';

import isNull from 'lodash/isNull';

import PaymentMethodsHub from '@Shared/Payments/PaymentMethodsHub/PaymentMethodsHub';
import {
  ACTIONS, ADYEN_VERSION_THRESHOLD, DEFAULT_PAYMENT_PROVIDER, EDIT_BOOKING_VARIANT, HUB_REDIRECT_FLOW_STATUS, RESPONSE_STATUS, SOURCE_PAGES,
} from '@Shared/Payments/PaymentMethodsHub/constants/paymentMethodsHub';

import { commonImplementationHelper } from '@Shared/Payments/PaymentMethodsHub/commonImplementationHelper';

import { PaymentMethodsHubComposable } from '@Shared/Payments/PaymentMethodsHub/composables/PaymentMethodsHubComposable';

import { removeUrlQuery } from '@Shared/Payments/PaymentMethodsHub/composables/responseUtils';

import { useTheme } from '@/composable/Theme/useTheme';

export default {
  name: 'EditBookingPSD2Modal',

  components: {
    AlertComponent,
    PaymentMethodsHub,
  },

  inject: ['paymentSuccess'],

  props: {
    booking: {
      type: Object,
      default: () => ({}),
    },
    bookingUuid: {
      type: String,
      default: '',
    },
    bookingExtension: {
      type: Object,
      default: () => ({}),
    },

    hideCvc: {
      type: Boolean,
      default: false,
    },

    selectLocked: {
      type: Boolean,
      default: false,
    },
  },

  setup() {
    const { checkPaymentIsFinalStatus, setNewResponse, storeData } = commonImplementationHelper();

    const { fetchButtonSpecs } = useTheme();

    const { getAPIFromProvider } = PaymentMethodsHubComposable(storeData);

    const providerVersionRegex = /\d/;

    return {
      checkPaymentIsFinalStatus,
      setNewResponse,
      fetchButtonSpecs,

      getAPIFromProvider,

      providerVersionRegex,
    };
  },

  data() {
    return {
      errorMessage: null,
      isPaymentMethodValid: false,
      currentPaymentMethod: null,
      PaymentResponse: null,
      isPaymentLoading: false,
    };
  },

  computed: {
    adyenProviderVersion() {
      return this.$featureFlag.flags.csrev3524EditBooking ? 'Adyen/5.59.0' : DEFAULT_PAYMENT_PROVIDER;
    },

    getProviderVersion() {
      const version = this.providerVersionRegex.exec(this.adyenProviderVersion)[0];

      return Number(version) || null;
    },
  },

  watch: {
    paymentSuccess({ response }) {
      this.setIsPaymentLoading(!this.checkPaymentIsFinalStatus(response));
    },
  },

  created() {
    this.ALERT_TYPES = ALERT_TYPES;
    this.ACTIONS = ACTIONS;
  },

  methods: {
    closeModal() {
      this.errorMessage = null;
      this.$emit('close-modal', false);
    },

    onPaymentMethodValid(method) {
      this.setIsPaymentValid(method.isValid);
      this.setCurrentPaymentMethod(method);
      this.setErrorMsg(null);
    },

    setErrorMsg(message) {
      this.errorMessage = message;
    },

    setIsPaymentValid(isValid) {
      this.isPaymentMethodValid = isValid;
    },

    setIsPaymentLoading(isLoading) {
      this.isPaymentLoading = isLoading;
    },

    setCurrentPaymentMethod(method) {
      this.currentPaymentMethod = method;
    },

    async submitPayment() {
      this.setErrorMsg(null);

      const { isValid } = this.currentPaymentMethod;

      if (!isValid) {
        return;
      }

      const { securityCode } = this.currentPaymentMethod.paymentMethod;

      const request = {
        ...this.booking,
        ...this.currentPaymentMethod,
        securityCode,
      };

      this.setIsPaymentLoading(true);

      let originalResponse = {};

      const api = await this.getAPIFromProvider(this.adyenProviderVersion);

      const AdyenVersionAboveThreshold = this.getProviderVersion && this.getProviderVersion > ADYEN_VERSION_THRESHOLD;

      let providedInvoiceUuid = null;

      try {
        if (!AdyenVersionAboveThreshold) {
          originalResponse = await api.putEditBookingPayment(this.bookingUuid, request);
        } else {
          const transformedBooking = camelCaseKeys(this.booking);

          const {
            start, end, userUuid, tag, insuranceUuid, destinationLocationUuid,
          } = transformedBooking;

          const editBookingPayload = {
            userUuid,
            start,
            end,
            tag,
            insuranceUuid,
            destinationLocationUuid,
          };

          const invoice = await api.putEditBookingPayment(this.bookingUuid, editBookingPayload);

          const { invoiceUuid, resourceType, resourceUuid } = invoice;

          const invoiceRequest = {
            ...this.currentPaymentMethod,
            paymentMethodUuid: this.currentPaymentMethod.paymentMethod.uuid,
            invoiceUuid,
            resourceType,
            resourceUuid,
          };

          providedInvoiceUuid = invoiceUuid;

          originalResponse = await api.payInvoice(invoiceRequest);
        }
      } catch (error) {
        logger.message(error, LOG_TYPE.error);

        originalResponse.providerStatus = RESPONSE_STATUS.error;
        originalResponse.providerMessage = error.response.data.message;
      }

      removeUrlQuery(window.location.href, window.location.search);

      const response = camelCaseKeys(originalResponse);

      if (response.providerStatus === RESPONSE_STATUS.refused) {
        this.setErrorMsg(response.providerMessage);
      }

      if (
        response.providerStatus === RESPONSE_STATUS.pending
        && !this.$router.history.current.fullPath.includes(HUB_REDIRECT_FLOW_STATUS.toRedirect)
      ) {
        this.$router.push({ query: { status: HUB_REDIRECT_FLOW_STATUS.toRedirect } }).catch(navigationErrorHandler);
      }

      const matchingResponse = {
        ...response,
        matchingName: `${this.bookingUuid}${EDIT_BOOKING_VARIANT.splitter}${EDIT_BOOKING_VARIANT.editTime}`,
        invoiceUuid: providedInvoiceUuid,
      };

      const hierarchy = {
        ancestor: SOURCE_PAGES.editBooking,
        parent: this.bookingUuid,
      };

      const finalResponse = this.setNewResponse(matchingResponse, response.providerStatus, hierarchy);

      if (isNull(finalResponse)) {
        this.setIsPaymentLoading(false);
      } else {
        this.PaymentResponse = finalResponse;
      }
    },
  },
};
</script>

<template>
  <ui-modal
    data-test-id="edit-booking-psd2-modal"
    open
  >
    <div
      slot="body"
      data-test-id="psd2-edit-booking-modal"
      class="text-left"
    >
      <AlertComponent
        v-if="errorMessage"
        :type="ALERT_TYPES.danger"
        class="w-100 mb-3"
        data-test-id="modal-error"
      >
        {{ errorMessage }}
      </AlertComponent>

      <PaymentMethodsHub
        :action="ACTIONS.select"
        :provider="adyenProviderVersion"
        :hide-cvc="hideCvc"
        :select-locked="selectLocked"
        :handle-external-response="PaymentResponse"
        @update:helper-component-active="setIsPaymentValid"
        @update:payment-method-valid="onPaymentMethodValid"
        @on:payment-error="setErrorMsg"
      />

      <div class="mt-4">
        <div class="emobg-border-horizontal-2 emobg-border-color-ground-light py-3 mb-2">
          <div class="row">
            <div class="col-10">
              {{ $t('modal.edit_booking_time.extend_booking') }} {{ bookingExtension.extension_time }}
            </div>

            <div class="col-2 text-right">
              {{ bookingExtension.total }}
            </div>
          </div>
        </div>

        <div class="row emobg-font-weight-bold emobg-font-medium">
          <div class="col-10">
            {{ $t('modal.edit_booking_time.total') }}:
          </div>

          <div class="col-2 text-right">
            {{ bookingExtension.total }}
          </div>
        </div>
      </div>
    </div>

    <div
      slot="footer"
      class="d-flex align-items-center justify-content-center
          emobg-background-color-white emobg-border-radius-medium"
    >
      <ui-button
        v-bind="fetchButtonSpecs({ buttonType: THEME_BUTTON_TYPES.SECONDARY })"
        :disabled="isPaymentLoading"
        class="emobg-color-white-contrast mr-2"
        data-test-id="cancel-button"
        @clickbutton="closeModal"
      >
        {{ $t('buttons.cancel') }}
      </ui-button>

      <ui-button
        v-bind="fetchButtonSpecs()"
        :disabled="!isPaymentMethodValid"
        :loading="isPaymentLoading"
        data-test-id="confirm-button"
        @clickbutton="submitPayment"
      >
        {{ $t('buttons.confirm') }}
      </ui-button>
    </div>
  </ui-modal>
</template>
