<template>
  <div>
    <MuiValidationWrapper
      id="editBookingPeopleForm"
      :extra-conditions="[hasValidAdditionalDrivers]"
      @areAllValid="valid => isFormValid = valid"
      @submit="confirmEditBookingPeople"
    >
      <ui-modal
        v-bind="$attrs"
        :size="SIZES.small"
        :open="value"
        :header="$t('buttons.edit_people_from_trip')"
        closable
        data-test-id="edit-booking-people-modal"
        @close="() => $emit('on:close', { isEdited: false })"
      >
        <div slot="body">
          <div class="emobg-font-small">
            <h6 class="my-2 emobg-font-weight-bold">
              {{ $t('carsharing_personal.additional_passengers_title', { free_seats: actualFreeSeats }) }}
            </h6>
            <div v-if="isAdditionalDriverEnabled">
              <AdditionalDriversComponent
                ref="additionalDriverInput"
                :selected-drivers="newAdditionalDrivers"
                :disabled="isDisabled"
                :has-invalid-drivers="size(invalidAdditionalDrivers) > 0"
                :booking-owner-uuid="userUuid"
                data-test-id="additional_driver-select"
                class="mb-3"
                @add-driver="addAdditionalDriver"
              />
              <RemovableBadgeComponent
                v-for="(driver, index) in newAdditionalDrivers"
                :key="`driver_added-${index}`"
                :on-remove="() => removeAdditionalDriver(driver)"
                :data-test-id="`added_driver_${index}`"
                :color="getDriverBadgeColor(invalidAdditionalDrivers, driver)"
                :editable="!isLoading"
                class="mb-2 mr-2"
              >
                {{ driver }}
              </RemovableBadgeComponent>
              <AlertComponent
                v-for="(invalidDrivers, reasonKey) in groupBy(invalidAdditionalDrivers, 'reason')"
                :key="`invalidDriver-${reasonKey}`"
                :type="ALERT_TYPES.danger"
                class="w-100 py-2"
              >
                <span class="ml-2 emobg-font-small emobg-font-weight-normal">
                  {{ $t(`api.errors.${reasonKey}`) }}
                  <br>
                  {{ map(invalidDrivers, 'email').join(', ') }}
                </span>
              </AlertComponent>
            </div>
            <div v-if="isAdditionalPassengersEnabled">
              <AdditionalPassengersComponent
                :disabled="isDisabled"
                class="mb-3"
                @add-passenger="passenger => newPassengers = concat(newPassengers, passenger)"
              />

              <RemovableBadgeComponent
                v-for="(passenger, index) in newPassengers"
                :key="`passenger_added-${index}`"
                :color="webAppColors.informative"
                :data-test-id="`passenger_added-${index}`"
                :on-remove="() => newPassengers = without(newPassengers, passenger)"
                :editable="!isLoading"
                class="mr-2"
              >
                {{ passenger }}
              </RemovableBadgeComponent>
            </div>
          </div>
        </div>
        <div
          slot="footer"
          class="d-flex flex-wrap justify-content-end"
          data-test-id="ui-modal--footer"
        >
          <ui-button
            v-for="(button, index) in getModalButtons"
            :key="`button-${index}`"
            :class="[
              'my-1',
              {
                'ml-3': index
              }
            ]"
            v-bind="button.attributes"
            v-on="button.listeners"
          >
            {{ button.text }}
          </ui-button>
        </div>
      </ui-modal>
    </MuiValidationWrapper>
    <FeedbackModalComponent
      v-model="modal.isOpen"
      v-bind="modal"
    />
  </div>
</template>
<script>
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import size from 'lodash/size';
import includes from 'lodash/includes';
import concat from 'lodash/concat';
import map from 'lodash/map';
import reject from 'lodash/reject';
import filter from 'lodash/filter';
import sortBy from 'lodash/sortBy';
import without from 'lodash/without';
import groupBy from 'lodash/groupBy';
import isEqual from 'lodash/isEqual';

import { mapActions, mapGetters } from 'vuex';
import { MuiValidationWrapper } from '@emobg/motion-ui/v1';
import { BUTTON_TYPES } from '@emobg/web-components';
import { external } from '@emobg/web-api-client';
import { LOG_TYPE, logger } from '@emobg/web-utils';
import { SEGMENT_EVENTS } from '@/constants/segment';

import AdditionalDriversComponent from '@/components/AdditionalDrivers/AdditionalDriversComponent';
import AdditionalPassengersComponent from '@/components/AdditionalPassengers/AdditionalPassengersComponent';
import RemovableBadgeComponent from '@/components/RemovableBadge/RemovableBadgeComponent';
import FeedbackModalComponent from '@Shared/components/FeedbackModal/FeedbackModalComponent';
import AlertComponent from '@/components/Alert/AlertComponent';

import { nameSpace as UserDataNameSpace } from '@/vue/stores/UserData/UserDataStore';
import {
  ACTIONS as VehicleUserActions,
  NAMESPACE as VehicleUserNamespace,
} from '@/stores/VehicleUser/VehicleUserModule';
import { useSegment } from '@/composable/Segment/segment';

import { genericErrorArgs, genericSuccessArgs } from '@/constants/defaultModalArgs';
import { getDriverBadgeColor } from '@/components/AdditionalDrivers/helpers/badgeHelpers';
import { algoliaFilterForAdditionalDrivers } from '@/utils/algoliaHelpers';
import { parseApiErrorMessage } from '@/utils/apiHelpers';
import ALERT_TYPES from '@/components/Alert/alertTypes';
import { successThanks } from '@/utils/publicImages';
import { useTheme } from '@/composable/Theme/useTheme';
import { webAppColors } from '@/constants/themes';

export default {
  name: 'EditBookingPeopleModal',
  components: {
    MuiValidationWrapper,
    AdditionalDriversComponent,
    AdditionalPassengersComponent,
    RemovableBadgeComponent,
    FeedbackModalComponent,
    AlertComponent,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    bookingUuid: {
      type: String,
      default: null,
    },
    bookingType: {
      type: String,
      default: null,
    },
    ownerEmail: {
      type: String,
      default: '',
    },
    isAdditionalDriverEnabled: {
      type: Boolean,
      default: false,
    },
    isAdditionalPassengersEnabled: {
      type: Boolean,
      default: false,
    },
    isPreBooking: {
      type: Boolean,
      default: false,
    },
    drivers: {
      type: Array,
      default: () => [],
    },
    passengers: {
      type: Array,
      default: () => [],
    },
    availableSeats: {
      type: Number,
      default: 0,
    },
    userUuid: {
      type: String,
      default: undefined,
    },
  },

  setup() {
    const { trackSegment } = useSegment();
    const { fetchButtonSpecs } = useTheme();

    return { fetchButtonSpecs, trackSegment, webAppColors };
  },
  data() {
    return {
      newAdditionalDrivers: [],
      newPassengers: [],
      invalidAdditionalDrivers: [],
      isLoading: false,
      isFormValid: true,
      errorMessage: null,
      modal: {
        isOpen: false,
      },
    };
  },

  computed: {
    ...mapGetters(UserDataNameSpace, ['userCompany']),
    isDisabled() {
      return this.isLoading || this.actualFreeSeats <= 0;
    },
    getPassengersDiff() {
      return size(this.newPassengers) - size(this.passengers);
    },
    actualFreeSeats() {
      const additionalDriverDiff = size(this.newAdditionalDrivers) - size(this.drivers);
      const freeSeatsResult = this.availableSeats - (additionalDriverDiff + this.getPassengersDiff);
      return freeSeatsResult > 0 ? freeSeatsResult : 0;
    },
    hasValidAdditionalDrivers() {
      return size(this.invalidAdditionalDrivers) <= 0;
    },
    driversHadChanged() {
      return !isEqual(this.drivers, this.newAdditionalDrivers);
    },
    passengersHadChanged() {
      return !isEqual(this.passengers, this.newPassengers);
    },
    hadChanged() {
      return this.driversHadChanged || this.passengersHadChanged;
    },
    getModalButtons() {
      return [
        {
          attributes: {
            ...this.fetchButtonSpecs({ buttonType: this.THEME_BUTTON_TYPES.SECONDARY }),
            class: 'mr-2',
            disabled: this.isLoading,
            'data-test-id': 'close-modal',
          },
          listeners: {
            clickbutton: () => this.$emit('on:close', { isEdited: false }),
          },
          text: this.$t('buttons.cancel'),
        },
        {
          attributes: {
            ...this.fetchButtonSpecs(),
            type: BUTTON_TYPES.submit,
            loading: this.isLoading,
            disabled: !this.isFormValid || !this.hadChanged,
            class: 'mr-2',
            'data-test-id': 'submit_edit_booking_edition-button',
          },
          text: this.$t('buttons.confirm'),
        },
      ];
    },
  },

  watch: {
    value() {
      this.initFormValues();
    },
  },

  created() {
    this.initFormValues();
    this.BUTTON_TYPES = BUTTON_TYPES;
    this.ALERT_TYPES = ALERT_TYPES;
  },

  methods: {
    map,
    size,
    groupBy,
    concat,
    without,
    getDriverBadgeColor,
    algoliaFilterForAdditionalDrivers,
    ...mapActions(VehicleUserNamespace, {
      validateDrivers: VehicleUserActions.validateDrivers,
    }),
    trackEvent(name) {
      const { bookingUuid, bookingType, errorMessage } = this;
      this.trackSegment({
        name,
        data: {
          bookingUuid,
          bookingType,
          errorMessage,
          usageStatus: get(this, 'booking.usage_status_key'),
        },
      });
    },
    initFormValues() {
      this.invalidAdditionalDrivers = [];
      this.newAdditionalDrivers = this.drivers || [];
      this.newPassengers = this.passengers || [];
    },
    addAdditionalDriver(driver) {
      this.newAdditionalDrivers = includes(this.newAdditionalDrivers, driver)
        ? this.newAdditionalDrivers
        : concat(this.newAdditionalDrivers, driver);
    },
    removeAdditionalDriver(driver) {
      this.newAdditionalDrivers = without(this.newAdditionalDrivers, driver);
      this.invalidAdditionalDrivers = reject(this.invalidAdditionalDrivers, ['email', driver]);
    },
    async validateAdditionalDrivers() {
      return this.isAdditionalDriverEnabled && this.driversHadChanged
        ? this.getDriversAreValid()
        : true;
    },
    async updateBookingPeople(driversDifference, passengersDifference) {
      // TODO: [CF-102] REFACTOR VEHICLEUSER STORE
      const updateOperations = [
        {
          action: () => external.booking.postAdditionalDrivers(
            this.bookingUuid,
            { emails: this.newAdditionalDrivers },
          ),
          sortCriteria: driversDifference,
          executionCriteria: this.driversHadChanged,
        },
        {
          action: () => external.booking.postUpdateBookingPassengers(this.bookingUuid, {
            booking_uuid: this.bookingUuid,
            passengers: this.newPassengers,
            free_seats: this.actualFreeSeats,
          }),
          sortCriteria: passengersDifference,
          executionCriteria: this.passengersHadChanged,
        },
      ];
      const filteredOperations = filter(updateOperations, 'executionCriteria');
      const sortedOperations = sortBy(filteredOperations, 'sortCriteria');

      await sortedOperations.reduce(async (acc, { action }) => {
        await acc;
        await action();
      }, undefined);
    },
    async confirmEditBookingPeople() {
      this.isLoading = true;
      const areDriversValid = await this.validateAdditionalDrivers();
      const driversDifference = size(this.newAdditionalDrivers) - size(this.drivers);

      if (areDriversValid) {
        try {
          if (this.isPreBooking) {
            // TODO: [CF-102] REFACTOR VEHICLEUSER STORE
            await external.preBooking.patchPreBooking(
              this.bookingUuid,
              {
                user_uuid: this.userUuid,
                drivers: this.newAdditionalDrivers,
                passengers: this.newPassengers,
              },
            );
          } else {
            await this.updateBookingPeople(driversDifference, this.getPassengersDiff);
          }

          this.modal = {
            ...genericSuccessArgs(this.$t),
            title: this.$t('modal.edit_booking_people.success_message'),
            image: successThanks,
            primaryCallToAction: () => {
              this.modal = { ...this.modal, isOpen: false };
              this.$emit('on:close', { isEdited: true });
            },
            primaryCallToActionText: this.$t('buttons.got_it'),
          };
        } catch (error) {
          this.errorMessage = parseApiErrorMessage(this.$t, this.$i18n, error);
          logger.message(this.errorMessage, LOG_TYPE.error);
          this.modal = {
            ...genericErrorArgs(this.$t),
            primaryCallToAction: () => { this.modal = { ...this.modal, isOpen: false }; },
          };
        } finally {
          if (driversDifference) {
            this.trackEvent(SEGMENT_EVENTS.editBookingAdditionalDriver);
          }

          if (this.getPassengersDiff) {
            this.trackEvent(SEGMENT_EVENTS.editBookingPassengers);
          }

          this.modal = { ...this.modal, isOpen: true };
        }
      }
      this.isLoading = false;
    },
    async getDriversAreValid() {
      try {
        this.invalidAdditionalDrivers = await this.validateDrivers({
          companyUuid: get(this, 'userCompany.uuid'),
          emails: this.newAdditionalDrivers,
        });
        return isEmpty(this.invalidAdditionalDrivers);
      } catch (error) {
        this.modal = {
          ...genericErrorArgs(this.$t),
          isOpen: true,
        };
        return false;
      }
    },
  },
};
</script>
