<template>
  <div>
    <ui-modal
      :open="isOpen"
      :size="SIZES.small"
      :header="$t('modal.edit_booking_time.title')"
      closable
      data-test-id="edit_booking_time-modal"
      @close="() => $emit('on:close', { isEdited: false })"
    >
      <div slot="body">
        <div>
          <AlertComponent
            v-if="mustDisplayCarpoolingAlert"
            :type="ALERT_TYPES.warning"
            class="w-100 my-2"
            data-test-id="carpooling_change_hours-alert"
          >
            {{ $t('MyBookings.EditBookingTimeModal.alerts.carpooling_return_trip.message') }}
          </AlertComponent>
        </div>
        <ui-skeleton
          v-if="isLoading.bookingData"
          height="220"
          class="my-4"
        />
        <div v-else>
          <BookingDatetimepicker
            v-if="booking && datetimepickerProps"
            v-bind="datetimepickerProps"
            :start="currentStart"
            :end="currentEnd"
            :text-start="get(bookingOptions, 'text.from')"
            :text-end="get(bookingOptions, 'text.until')"
            :is-booking-request="isBookingRequest"
            is-vertical
            @update:start="updateStart"
            @update:end="updateEnd"
          />
          <ui-skeleton
            v-if="isLoading.bookingExtension"
            height="120"
            class="my-4 d-block"
          />
          <div
            v-if="hasBookingExtensionCost && !isLoading.bookingExtension"
            data-test-id="booking-extension"
            class="emobg-font-small mt-4"
          >
            <table class="table tableFees mb-0">
              <thead>
                <tr>
                  <th
                    colspan="2"
                    class="border-top-0"
                    scope="table-title"
                  >
                    {{ $t('modal.edit_booking_time.edit_time') }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th
                    data-test-id="extension-time"
                    class="emobg-font-default"
                  >
                    {{ $t('modal.edit_booking_time.extend_booking') }} {{ bookingExtension.extension_time }}
                  </th>
                  <td
                    data-test-id="extension-price"
                    class="text-right"
                  >
                    {{ bookingExtension.extension_price }}
                  </td>
                </tr>
                <tr class="emobg-font-weight-bold emobg-font-medium">
                  <th>{{ $t('modal.edit_booking_time.total') }}</th>
                  <td
                    class="text-right"
                    data-test-id="total-charge"
                  >
                    {{ bookingExtension.total }}
                  </td>
                </tr>
                <tr>
                  <td
                    data-test-id="mileage-price"
                    class="text-right"
                  >
                    {{ bookingExtension.mileage_price }}
                  </td>
                  <td />
                </tr>
              </tbody>
            </table>
          </div>
          <div
            v-if="displayNotRefundAlert"
            data-test-id="refund-alert"
            class="text-center emobg-font-small"
          >
            <strong
              data-test-id="title"
              class="emobg-color-danger mb-1"
            >
              {{ $t('modal.edit_booking_time.alert_refund.title') }}
            </strong>
            <strong
              data-test-id="message"
              class="mt-2"
            >
              {{ $t('modal.edit_booking_time.alert_refund.message') }}
            </strong>
          </div>
        </div>
        <AlertComponent
          v-if="isOutsideWorkingHours"
          :type="ALERT_TYPES.warning"
          class="w-100 mb-3"
          data-test-id="alert-outside-working-hours"
        >
          {{ $t('new_booking.alerts.outside_working_hours') }}
        </AlertComponent>
      </div>
      <div
        slot="footer"
        class="d-flex flex-wrap justify-content-end"
        data-test-id="ui-modal--footer"
      >
        <ui-button
          v-for="(button, index) in getModalButtons"
          :key="`button-${index}`"
          :class="[
            'my-1',
            {
              'ml-3': index
            }
          ]"
          v-bind="button.attributes"
          v-on="button.listeners"
        >
          {{ button.text }}
        </ui-button>
      </div>
    </ui-modal>
    <FeedbackModalComponent
      v-model="modals.active.isOpen"
      v-bind="modals.active"
    />
    <EditBookingPSD2Modal
      v-if="isPSD2ModalVisible"
      :booking="bookingPSD2"
      :booking-uuid="booking.uuid"
      :booking-extension="bookingExtension"
      :select-locked="selectLocked"
      @close-modal="closePSD2Modal"
    />
  </div>
</template>
<script>
// Packages
import moment from 'moment';
import get from 'lodash/get';
import some from 'lodash/some';
import isNil from 'lodash/isNil';
import debounce from 'lodash/debounce';
import head from 'lodash/head';
import delay from 'lodash/delay';
import pick from 'lodash/pick';
import camelCase from 'lodash/camelCase';
import map from 'lodash/map';
import isEmpty from 'lodash/isEmpty';
import isNumber from 'lodash/isNumber';

import { mapState } from 'vuex';
import {
  DATE_FORMAT, DELAY, LOG_TYPE, logger, SPEED,
} from '@emobg/web-utils';
import { external } from '@emobg/web-api-client';

// Utils
import { parseApiErrorMessage } from '@/utils/apiHelpers';

// Components
import FeedbackModalComponent from '@Shared/components/FeedbackModal/FeedbackModalComponent';
import AlertComponent from '@/components/Alert/AlertComponent';

import EditBookingPSD2Modal from '@/components/MyBookings/modals/EditBookingPSD2Modal/EditBookingPSD2Modal';
import BookingDatetimepicker from '@/components/BookingDatetimepicker/BookingDatetimepicker';

// Stores
import { userData } from '@/stores/User/UserData/UserDataMapper';
import { nameSpace as UserDataNameSpace } from '@/vue/stores/UserData/UserDataStore';
import { bookingRulesData, fetchBookingRules } from '@/stores/Booking/BookingMapper';
import { fetchCSOperator, findCSOperatorByUUID } from '@/stores/CSOperator/CSOperatorMapper';
import { getCurrentProfileUuid, isBusinessProfileActive, isPersonalProfileActive } from '@/stores/User/Profile/ProfileMapper';

// Constants
import { BOOKING_TYPES } from '@/constants/bookingTypes';
import { SEGMENT_EVENTS } from '@/constants/segment';
import ALERT_TYPES from '@/components/Alert/alertTypes';

import { bookingRulesOperatorConfigs } from '@/helpers/csOperator/configuration';
import { getAbbreviation } from '@/helpers/booking/bookingHelpers';
import { REQUIRED_CS_OPERATOR_CONFIGS } from '@/components/BookingDatetimepicker/constants/csOperatorConfigRequired';

import { editBookingTimeError, editBookingTimeSuccess } from '@/utils/publicImages';
import { genericErrorArgs, genericSuccessArgs } from '@/constants/defaultModalArgs';
import { DATE_UNITS } from '@/constants/dates';
import { useSegment } from '@/composable/Segment/segment';

import { useTheme } from '@/composable/Theme/useTheme';
import { BOOKING_USAGE_STATUS } from '@/constants/bookingUsageStatus.const';
import { PREBOOKING_ERRORS } from '../constants/prebookingErrors.const';

export default {
  name: 'EditBookingTimeModal',

  components: {
    EditBookingPSD2Modal,
    FeedbackModalComponent,
    AlertComponent,
    BookingDatetimepicker,
  },

  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
    booking: {
      type: Object,
      default: () => ({}),
    },
    selectLocked: {
      type: Boolean,
      default: false,
    },
  },

  setup() {
    const { trackSegment } = useSegment();
    const { fetchButtonSpecs } = useTheme();

    return { fetchButtonSpecs, trackSegment };
  },

  data() {
    return {
      bookingPSD2: {},
      isPSD2ModalVisible: false,
      bookingOptions: undefined,
      bookingExtension: undefined,
      bookingOperator: undefined,
      datetimepickerManager: undefined,
      datetimepickerProps: undefined,
      currentStart: moment(this.booking.start),
      currentEnd: moment(this.booking.end),
      errorMessage: null,
      modals: {
        active: {
          isOpen: false,
        },
      },
      isLoading: {
        bookingExtension: false,
        bookingData: false,
        updateBooking: false,
      },
    };
  },

  computed: {
    findCSOperatorByUUID,
    getCurrentProfileUuid,
    isPersonalProfileActive,
    isBusinessProfileActive,
    bookingRulesData,
    userData,
    ...mapState(UserDataNameSpace, {
      isCompanyPays: state => get(state, 'userData.company.company_pays'),
    }),
    bookingType() {
      return get(
        this.booking,
        'cs_booking_use_case.booking_type',
        BOOKING_TYPES.carsharing,
      );
    },
    bookingPeriod() {
      return {
        start: moment(get(this, 'booking.start')),
        end: moment(get(this, 'booking.end')),
      };
    },
    bookingCsOperatorUuid() {
      return (
        get(this, 'booking.csOperator.uuid') || get(this, 'booking.cs_operator_uuid')
      );
    },
    areButtonsDisabled() {
      return this.isWaitingApiCall || !this.hadDateChanged;
    },
    isUpdating() {
      return this.hadDateChanged && this.isWaitingApiCall;
    },
    isWaitingApiCall() {
      return some([
        this.isLoading.bookingExtension,
        this.isLoading.bookingData,
      ]);
    },
    isLoadingBookingData() {
      return this.isLoading.bookingData || this.isLoading.bookingExtension;
    },
    hasUnpricedTarrif() {
      return get(this.bookingOptions, 'is_unpriced_tariff', false);
    },
    hasBookingExtensionCost() {
      return this.bookingExtension && !this.hasUnpricedTarrif;
    },
    isBookingRequest() {
      return get(this, 'booking.isBookingRequest');
    },
    displayNotRefundAlert() {
      return !this.isLoading.bookingExtension && get(this, 'bookingExtension.is_not_reimbursable', false);
    },
    isOutsideWorkingHours() {
      return get(this, 'bookingExtension.outside_working_hours', false);
    },
    mustDisplayCarpoolingAlert() {
      const isCarpooling = get(this, 'booking.is_carpooling', false);

      const originalEnd = get(this, 'bookingPeriod.end');
      const currentEnd = get(this, 'currentEnd');
      return !this.isWaitingApiCall && !isNil(originalEnd) && !originalEnd.isSame(currentEnd) && isCarpooling;
    },
    hadDateChanged() {
      return !(this.bookingPeriod.start.isSame(this.currentStart)
          && this.bookingPeriod.end.isSame(this.currentEnd));
    },
    getModalButtons() {
      return [
        {
          attributes: {
            ...this.fetchButtonSpecs({ buttonType: this.THEME_BUTTON_TYPES.SECONDARY }),
            class: 'mr-2',
            'data-test-id': 'cancel-button',
            disabled: this.areButtonsDisabled,
          },
          listeners: {
            clickbutton: () => this.$emit('on:close', { isEdited: false }),
          },
          text: this.$t('buttons.cancel'),
        },
        {
          attributes: {
            ...this.fetchButtonSpecs(),
            loading: this.isLoading.updateBooking,
            disabled: this.areButtonsDisabled || this.isUpdating,
            class: 'mr-2',
            'data-test-id': 'submit_booking_time-button',
          },
          listeners: {
            clickbutton: this.confirmEditBookingTime,
          },
          text: this.$t('buttons.confirm'),
        },
      ];
    },
  },

  watch: {
    booking() {
      this.syncData();
    },
  },

  created() {
    this.ALERT_TYPES = ALERT_TYPES;
    this.successModalArgs = {
      ...genericSuccessArgs(this.$t),
      title: this.$t('modal.edit_booking_time.success.title'),
      primaryCallToAction: () => {
        this.modals.active.isOpen = false;
        this.$emit('on:close', { isEdited: true });
      },
      image: editBookingTimeSuccess,
      primaryCallToActionText: this.$t('buttons.got_it'),
    };
    this.errorModalArgs = {
      ...genericErrorArgs(this.$t),
      title: this.$t('modal.edit_booking_time.error.title'),
      primaryCallToAction: () => {
        this.modals.active.isOpen = false;
        this.$emit('on:close', { isEdited: true });
      },
      primaryCallToActionText: this.$t('modal.edit_booking_time.error.try_again'),
      image: editBookingTimeError,
    };

    this.syncData();
  },

  methods: {
    get,
    fetchCSOperator,
    fetchBookingRules,

    trackEvent(name) {
      const { bookingType, errorMessage } = this;
      this.trackSegment({
        name,
        data: {
          bookingType,
          errorMessage,
          bookingUuid: get(this, 'booking.uuid'),
          fleetType: get(this, 'booking.fleet') || get(this, 'booking.cs_booking_use_case.fleet'),
          usageStatus: get(this, 'booking.usage_status_key'),
          originalStart: moment(get(this, 'bookingPeriod.start')).format(DATE_FORMAT.isoDateTime),
          originalEnd: moment(get(this, 'bookingPeriod.end')).format(DATE_FORMAT.isoDateTime),
          updatedStart: this.currentStart.format(DATE_FORMAT.isoDateTime),
          updatedEnd: this.currentEnd.format(DATE_FORMAT.isoDateTime),
        },
      });
    },

    async getBookingData() {
      const vehicleCategoryUuid = get(this, 'booking.vehicle_category.uuid');

      this.isLoading.bookingData = true;

      this.bookingOperator = this.findCSOperatorByUUID(this.bookingCsOperatorUuid);

      const [
        operator,
        bookingOptions,
      ] = await Promise.all([
        this.bookingOperator || this.fetchCSOperator(this.bookingCsOperatorUuid),
        !this.isBookingRequest && external.booking.getUpdateAvailabilityInfo(this.booking.uuid),
        this.fetchBookingRules({
          bookingType: this.bookingType,
          userProfileUuid: this.getCurrentProfileUuid,
          vehicleUuid: get(this, 'booking.vehicle.uuid'),
          vehicleCategoryUuid,
          locationUuid: vehicleCategoryUuid && get(this, 'booking.location.original_uuid'),
        }),
      ]);

      this.bookingOperator = operator;
      this.bookingOptions = bookingOptions;

      const fleetSegmentRules = get(this, 'bookingRulesData');
      const abbreviation = getAbbreviation(this.bookingType);

      const csOperatorConfig = {
        ...get(operator, 'configuration'),
        [`${abbreviation}_minimum_booking_duration`]: isNumber(fleetSegmentRules.minimumBookingDuration)
          ? fleetSegmentRules.minimumBookingDuration
          : get(operator, `configuration.${abbreviation}_minimum_booking_duration`),
        [`${abbreviation}_maximum_booking_duration`]: isNumber(fleetSegmentRules.maximumBookingDuration)
          ? fleetSegmentRules.maximumBookingDuration
          : get(operator, `configuration.${abbreviation}_maximum_booking_duration`),
      };

      const bookingOperatorConfigs = bookingRulesOperatorConfigs({
        csOperatorConfig,
        bookingType: this.bookingType,
      });

      const allowedPastPeriod = this.bookingPeriod.start.isSameOrBefore(moment())
        ? this.bookingPeriod.start
        : moment().subtract(bookingOperatorConfigs.minutesToBookBeforeCurrentTime, DATE_UNITS.minutes);

      const isStartDisabled = this.bookingPeriod.start.isSameOrBefore(allowedPastPeriod)
        || get(this.booking, 'usage_status_key') === BOOKING_USAGE_STATUS.started;

      const previousEnd = get(this, 'bookingOptions.previous_booking_end');
      const pastLimitDate = isEmpty(previousEnd)
        ? allowedPastPeriod
        : moment(previousEnd);

      const nextStart = get(this, 'bookingOptions.next_booking_start');
      const futureLimitDate = isEmpty(nextStart)
        ? null
        : moment(nextStart);

      this.datetimepickerProps = {
        ...pick(bookingOperatorConfigs, map(REQUIRED_CS_OPERATOR_CONFIGS, camelCase)),
        isStartDisabled,
        pastLimitDate,
        futureLimitDate,
        allowedPastPeriod,
      };

      this.isLoading.bookingData = false;
    },

    debounceFetchBookingCost: debounce(function fetchBookingCost() {
      this.fetchBookingCost();
    }, SPEED.medium),

    updateStart(start) {
      const isNewStart = !this.currentStart.isSame(start);
      if (isNewStart) {
        this.currentStart = start;
        this.debounceFetchBookingCost();
      }
    },

    updateEnd(end) {
      const isNewEnd = !this.currentEnd.isSame(end);
      if (isNewEnd) {
        this.currentEnd = end;
        this.debounceFetchBookingCost();
      }
    },

    async fetchBookingCost() {
      if (this.isBookingRequest) {
        return;
      }

      const queryParams = {
        user_uuid: get(this, 'userData.uuid'),
        start: moment(this.currentStart).format(DATE_FORMAT.filter),
        end: moment(this.currentEnd).format(DATE_FORMAT.filter),
      };

      try {
        this.isLoading.bookingExtension = true;
        this.bookingExtension = await external.booking
          .getUpdatedCostInfo(get(this, 'booking.uuid'), queryParams);
      } catch (error) {
        const errorMessage = parseApiErrorMessage(this.$t, this.$i18n, error);
        logger.message(`There was a problem trying to get booking extension cost: ${errorMessage}`, LOG_TYPE.error);
        this.modals.active = { ...this.modals.active, ...this.errorModalArgs, description: errorMessage };
      } finally {
        this.isLoading.bookingExtension = false;
      }
    },

    async syncData() {
      await this.getBookingData();
      await this.fetchBookingCost();
    },

    async confirmEditBookingTime() {
      this.isLoading.updateBooking = true;

      const isPaymentRequired = get(this, 'bookingExtension.payment_required');

      if (this.isBookingRequest) {
        await this.updatePrebooking();
      } else if (isPaymentRequired && this.isPersonalProfileActive) {
        this.bookingPSD2 = {
          user_uuid: this.userData.uuid,
          start: this.currentStart.format(DATE_FORMAT.filter),
          end: this.currentEnd.format(DATE_FORMAT.filter),
          destination_location_uuid: get(this, 'booking.destination_location.uuid'),
          insurance_uuid: get(this, 'booking.insurance.uuid'),
          tag: head(get(this, 'booking.tags')),
        };
        this.isPSD2ModalVisible = true;
      } else {
        await this.updateBooking();
      }
      this.isLoading.updateBooking = false;
    },

    async updateBooking() {
      const payload = {
        user_uuid: this.userData.uuid,
        vehicle_uuid: get(this, 'booking.vehicle.uuid'),
        location_uuid: get(this, 'booking.location.uuid'),
        start: this.currentStart.format(DATE_FORMAT.filter),
        end: this.currentEnd.format(DATE_FORMAT.filter),
        source: 'webapp',
      };

      try {
        await external.booking.putUpdateBooking(get(this, 'booking.uuid'), payload);
        this.modals.active = { ...this.modals.active, ...this.successModalArgs };
      } catch (error) {
        this.errorMessage = parseApiErrorMessage(this.$t, this.$i18n, error);
        logger.message(`There was a problem trying to update the booking: ${this.errorMessage}`, LOG_TYPE.error);
        this.modals.active = { ...this.modals.active, ...this.errorModalArgs, description: this.errorMessage };
      } finally {
        this.trackEvent(SEGMENT_EVENTS.editBookingTime);
        this.modals.active.isOpen = true;
      }
    },

    async updatePrebooking() {
      const payload = {
        user_uuid: this.userData.uuid,
        start: this.currentStart.format(DATE_FORMAT.filter),
        end: this.currentEnd.format(DATE_FORMAT.filter),
      };

      try {
        await external.preBooking
          .patchPreBooking(get(this, 'booking.uuid'), payload)
          .then(this.delayPreBookingEdition);

        this.modals.active = { ...this.modals.active, ...this.successModalArgs };
      } catch (error) {
        if (get(error, 'response.data.key') === PREBOOKING_ERRORS.prebookingWithAssignedBooking) {
          this.modals.active = {
            ...this.modals.active,
            ...this.successModalArgs,
            description: this.$t('modal.edit_booking_time.pre_booking_with_assigned_booking'),
          };
        } else {
          this.errorMessage = parseApiErrorMessage(this.$t, this.$i18n, error);
          logger.message(`There was a problem trying to update the booking: ${this.errorMessage}`, LOG_TYPE.error);

          this.modals.active = { ...this.modals.active, ...this.errorModalArgs, description: this.errorMessage };
        }
      } finally {
        this.trackEvent(SEGMENT_EVENTS.editBookingTime);
        this.modals.active.isOpen = true;
      }
    },

    closePSD2Modal(status) {
      this.isPSD2ModalVisible = false;
      if (status) {
        this.modals.active = { ...this.modals.active, ...this.successModalArgs };
        this.modals.active.isOpen = true;
      }
    },

    delayPreBookingEdition(preBookingResponse) {
      // Backend does something asynchronous and we have to wait until
      // it will be finished (more or less)
      const delayTime = DELAY.extraLong + DELAY.medium;

      return new Promise(resolve => {
        delay(() => {
          resolve(preBookingResponse);
        }, delayTime);
      });
    },
  },
};
</script>
